import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { defer, map, Observable, shareReplay } from 'rxjs';

import { Environment, ENVIRONMENT } from '@environment';
import { UsheruApi } from '@usheru-hff/shared/data-access-backend';
import { Genre } from '@usheru-hff/shared/domain/genre';
import { TranslationsService } from '@usheru-hff/shared/utils-translation';

@Injectable({
  providedIn: 'root'
})
export class GenresService {
  private readonly usheruApi = inject(UsheruApi);
  private readonly http = inject(HttpClient);
  private readonly env = inject(ENVIRONMENT);
  private readonly headers = this.usheruApi.getStandardHeader();
  private readonly translationsService = inject(TranslationsService);

  private genres$ = defer(() =>
    this.http.get<Genre[]>(this.env.api.genresUrl(), {
      headers: this.headers,
      params: {
        trLanguage: this.translationsService?.currentLanguage().slug
      }
    })
  ).pipe(shareReplay(1));

  getGenres(): Observable<Genre[]> {
    return this.genres$;
  }

  getFilteredGenres(): Observable<Genre[]> {
    return this.getGenres().pipe(
      map(genres =>
        !this.env.genresFiltering
          ? genres
          : genres.filter(genre => this.env.genresFiltering?.some(genreSlug => genre.slug == genreSlug))
      )
    );
  }
}
